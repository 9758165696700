<template>
  <div id="page" class="dark">
    <app-header />
    <div class="container">
      <router-view/>
    </div>
    <app-bottom />
  </div>
</template>

<style lang="scss">
body {
  margin : 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

#app .container {
  width : 80%;
  max-width : 1024px;
  margin : auto;
  padding : 0 0em;
}

#app #page > .container {
  padding-top : 6em;
  min-height : 100%;

  padding-bottom : 2em;
}



.VerticalSpacer_Middle {
  min-height : 12em;
}



#app .dark {
  background-color : #555555;
}

#app .dark > .container {
  color: #bbbbbb;
}

#app .dark a {
  color: #746ad7;
}

#app .dark a:visited {
  color: #655dbc;
}

.dark #nav {
  a {
    font-weight: bold;
    color: #8278f2;

    &.router-link-exact-active {
      color: #655dbc;
    }
  }
}



#app .light {
  background-color : #ffffff;
}

#app .light > .container {
  color: #222222;
}

#app .light a {
  color: #746ad7;
}

#app .light a:visited {
  color: #655dbc;
}

.light #nav {
  a {
    font-weight: bold;
    color: #8278f2;

    &.router-link-exact-active {
      color: #655dbc;
    }
  }
}



@media screen and (max-width: 800px) {
  #app .dark > .container {
    color: #ffffff;
  }
}



/* Columns of images. */

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 22%;
  padding: 0 4px;
  height : 100%;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.rowHorizontal {
  display: flex;
  flex-wrap: wrap;
}

.rowHorizontal a {
  display : inline-block;
  width : 24%;
  height: 40vh;
  flex-grow: 1;
  margin: 0 0.5em 0.5em 0;
}

.rowHorizontal a img {
  /*max-height: 100%;*/
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

@media screen and (max-width: 1024px) {
  .rowHorizontal a {
    width : 23%;
  }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 48%;
  }

  .rowHorizontal a {
    width : 22%;
    height: 30vh;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }

  .rowHorizontal a {
    width : 22%;
    height: 20vh;
  }
}

@media screen and (max-width: 360px) {
  .rowHorizontal a {
    width : 20%;
  }
}
</style>



<script>
  import AppHeader from "@/components/AppHeader.vue";
  import AppBottom from "@/components/AppBottom.vue";

  export default {
    name: 'app',
    created () {
        document.title = "Administrative Web-Panel";
    },
    components : {
      AppHeader,
      AppBottom
    }
  }
</script>
