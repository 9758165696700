<template>
	<header>
		<div class="container">
			<h1>Logo</h1>

			<!-- <div id="nav">
				<router-link to="/">Home</router-link> |
				<router-link to="/about">About</router-link>
			</div> -->

			<div id="divThemeSelectorHolder">
				<select id="selectTheme" v-on:change="selectTheme_OnSelect">
					<option value="light">Light</option>
					<option value="dark">Dark</option>
				</select>
			</div>

			<div id="divContactsHolder">
			</div>
		</div>

		<div id="buttonAction">
		</div>
	</header>

</template>



<style lang="scss">
#app header {
	position: fixed;
	left : 0;
	top : 0;
	width : 100%;
}

#app header .container {
	max-height : 6em;
}

#app header .container > * {
	vertical-align : middle;
	display : inline-block;
}

#app h1 {
	display : inline-block;
}

#app h1 img {
	height : 2em;
}

#app header #nav {
	padding: 30px;
	display : none;
}

#app header #divThemeSelectorHolder {
	position : absolute;
	right : 12em;
	bottom : 1.35em;
}

#app header #divContactsHolder {
	position : absolute;
	right : 1em;
	bottom : 0.75em;
}

#app header #divContactsHolder img {
	height : 2em;
	margin-left : 0.5em;
}

#app #buttonAction {
	position: fixed;
	right : 1em;
	top : 12em;
	padding : 0.5em 0.5em;

	border-radius : 0.5em;

	background-color : #663b51;

	writing-mode: vertical-rl;
	text-orientation: upright;
}

#app #buttonAction a {
	color : #bbbbbb;
	text-decoration : none;
}



#app .dark header {
	color: #cf4e8f;
	background-color : #333333;
}

#app .light header {
	color: #cf4e8f;
	background-color : #333333;
}



@media screen and (max-width: 800px) {
	#app header #divContactsHolder {
		bottom : 0.5em;
		width : 6em;
	}

	#app header #divThemeSelectorHolder {
		right : 8em;
		bottom : 1.2em;
	}

	#app #buttonAction {
		right : 0em;
		top : 12em;
	}
}
</style>



<script>
	// import Vue from 'vue'

	// selectTheme = document.getElementById ( "selectTheme" );
	// function selectTheme_OnSelect () {
	// 	console.log ( selectTheme.options [ selectTheme.selectedIndex ].text );
	// };
	
	// function selectTheme_OnSelect ( event ) {
	// 	console.log(event.target.value);
	// }

	export default {
		mounted () {
			let theme = this.getCookie ( "theme" );
			// console.log ( theme );
			if ( theme !== "" ) {
				document.getElementById ( "selectTheme" ).value = theme;
			}
			document.getElementById ( "page" ).className = document.getElementById ( "selectTheme" ).value.toLowerCase ();
		},
		methods : {
			selectTheme_OnSelect: function ( event ) {
				// `event` is the native DOM event
				// alert(event.target.tagName);
				// console.log ( event.target.value.toLowerCase () );
				document.getElementById ( "page" ).className = event.target.value.toLowerCase ();

				this.setCookie ( "theme", event.target.value.toLowerCase (), 30 );
			},
			setCookie : function (cname, cvalue, exdays) {
				let d = new Date();
				d.setTime(d.getTime() + (exdays*24*60*60*1000));
				let expires = "expires="+ d.toUTCString();
				document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
			},
			getCookie : function ( cname ) {
				let name = cname + "=";
				let decodedCookie = decodeURIComponent(document.cookie);
				let ca = decodedCookie.split(';');
				for(let i = 0; i <ca.length; i++) {
					let c = ca[i];
					while (c.charAt(0) == ' ') {
						c = c.substring(1);
					}
					if (c.indexOf(name) == 0) {
						return c.substring(name.length, c.length);
					}
				}
				return "";
			}
		}
	};

</script>