import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'Projects',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Projects.vue')
  },
  // {
  //   path: '/project',
  //   name: 'Project',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Project.vue')
  // },
  {
    path: '/projects/:id',
    name: 'Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/Project.vue')
  },
  {
    path: '/machines',
    name: 'Machines',
    component: () => import(/* webpackChunkName: "about" */ '../views/Machines.vue')
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import(/* webpackChunkName: "about" */ '../views/Applications.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
