<template>
	<footer>
		<div class="container">
			<div id="nav">
				<router-link to="/">Home</router-link> |
				<router-link to="/projects">Projects</router-link> |
				<router-link to="/machines">Machines</router-link> |
				<router-link to="/applications">Applications</router-link>
			</div>
		</div>
	</footer>

</template> 



<style lang="scss">
#app footer {
	position: fixed;
	left : 0;
	bottom : 0;
	width : 100%;
}

#app footer .container {
	vertical-align : middle;
}

#app footer .container > * {
	vertical-align : middle;
}

#app footer #nav {
	display : inline-block;
	padding : 5px;
}



#app .dark footer {
	color: #cf4e8f;
	background-color : #444444;
}



#app .light footer {
	color: #cf4e8f;
	background-color : #444444;
}
</style>