
import { Options, Vue } from 'vue-class-component';

@Options({
  async mounted () {
    const passwordAdministrator = document.getElementById ( "passwordAdministrator" ) as HTMLInputElement;
    const btSave = document.getElementById ( "btSave" );

    if ( btSave && passwordAdministrator ) {
      btSave.addEventListener("click", async ( inputEvent ) => {
        console.log ( "btSave : onclick : Button is clicked." );

        this.setCookie ( "passwordAdministrator", passwordAdministrator.value, 30 );
      });
    }
  },
  methods : {
      setCookie : function (cname : string, cvalue : string, exdays : number) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },
  },
  props: {
    msg: String
  }
})
export default class HelloWorld extends Vue {
  msg!: string
}
